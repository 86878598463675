import React        from 'react';
import PropTypes    from 'prop-types';
import { Carousel } from 'react-responsive-carousel';

import HeaderImage from '../HeaderImage';

// eslint-disable-next-line
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';


// TODO make options configurable
class CarouselComponent extends React.Component {
  render() {
    return (
      this.props.children.length === 1
        ? <HeaderImage>{this.props.children}</HeaderImage>
        :
        <Carousel showIndicators={true}
                  showArrows={false}
                  showThumbs={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  showStatus={false}>
          {this.props.children}
        </Carousel>
    )
  }
}

CarouselComponent.propTypes = {
  carouselOptions: PropTypes.object,
};

export default CarouselComponent;
