import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import styled      from 'styled-components';

import Spacer        from '../components/Spacer';
import Heading       from '../components/Heading';
import Wrapper       from '../components/Wrapper';
import Carousel      from '../components/Carousel';
import CallToAction  from '../modules/CallToAction';
import DefaultLayout from '../layouts/Default';

// TODO not here...
export const Button = styled.a`
    // TODO don't hardcode #fff 
    background-color: ${({ theme, primary }) => primary ? theme.colors.brandColor : theme.colors.baseColor};
    color: ${({ theme, primary }) => primary ? '#fff' : theme.colors.brandColor};
    width: 100%;
    display: block;
    text-align: center;
    margin: 18px 0;
    padding: 12px 0;
    border: 1px solid ${({ theme }) => theme.colors.brandColor};
    border-radius: 2px;
    transition: background-color 0.2s;
    &:hover {
      background-color: ${({ theme, primary }) => primary ? theme.colors.baseColor : theme.colors.brandColor};
      color: ${({ theme, primary }) => primary ? theme.colors.brandColor : '#fff'};
    }
`;

class KonfiguratorenPage extends React.Component {
  render() {
    return (
      <DefaultLayout location={this.props.location}>
        <Carousel>
          {this.props.data.headerImages.edges.map((image) => (
            <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
          ))}
        </Carousel>
        <Wrapper>
          <Spacer/>
          <Heading>Unsere Konfiguratoren</Heading>
          <Button href='http://www.dertrauringjuwelier.de/egf/creative/konfigurator.php#F-1008-1' target='_blank' rel='noopener noreferrer'>Trauringjuwelier-Konfigurator</Button>
          <Button href='http://www.rubin-trauringe.de/ring-designer/catalog/' target='_blank' rel='noopener noreferrer'>Rubin Konfigurator</Button>
          {/*<Button href='http://insignety.com/de/ring-selbst-gestalten/' target='_blank' rel='noopener noreferrer'>Insignety-Ringdesigner</Button>*/}
          <p>Durch Klick auf die Links verlassen Sie unsere Seite.</p>
          <Spacer/>
          <CallToAction/>
          <Spacer/>
        </Wrapper>
      </DefaultLayout>
    )
  }
}

export default KonfiguratorenPage;

export const query = graphql`
  query KonfiguratorenPageQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/index"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
  }
`;
